import {
  RteRteLinks,
  useBanderoleQuery,
  useBlackfridayNewsletterModuleQuery,
  useNewsletterModuleQuery,
} from '@contentfulTypes'
import { DEFAULT_LOCALE } from 'config/constants'
import { createGraphQLClient } from 'graphql/contentfulClient'
import { useRouter } from 'next/router'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { Icon } from '~/elements/Icon/Icon'
import { NewsletterForm } from '~/modules/NewsletterModule/NewsletterForm'
import { Headline } from '~/elements/Headline/Headline'
import { RTEParser } from '~/lib/RTEParser/RTEParser'
import { Image } from '@next/image'
import clsx from 'clsx'

type Props = {
  open?: boolean
  close?(event: React.MouseEvent | React.KeyboardEvent): void
}

export const BanderoleNewsletter = ({ open, close }: Props) => {
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()

  const { data: banderoleData } = useBanderoleQuery(contentfulClient, {
    preview,
    locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
  })

  const id =
    banderoleData?.pageCollection?.items[0]?.banderoleNewsletter?.activeNewsletterBlockId || ''

  const { data: newsletterModule } = useBlackfridayNewsletterModuleQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )

  const { data: stadardNewsletterModule } = useNewsletterModuleQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )

  const banderoleItems =
    banderoleData?.pageCollection?.items[0]?.banderoleNewsletter?.banderoleNewsletterTitle

  const banderoleImage =
    banderoleData?.pageCollection?.items[0]?.banderoleNewsletter?.banderoleNewsletterImage?.url

  const banderolePopupCloseIconColor =
    banderoleData?.pageCollection?.items[0]?.banderoleNewsletter?.banderolePopupCloseIconColor

  const disclaimerOne =
    newsletterModule?.blackfridayNewsletterModule?.disclaimerOne ||
    stadardNewsletterModule?.newsletterModule?.disclaimer

  const disclaimerTwo =
    newsletterModule?.blackfridayNewsletterModule?.disclaimerTwo ||
    stadardNewsletterModule?.newsletterModule?.disclaimerRte

  return (
    <DialogOverlay
      className="z-50 overflow-hidden pt-20 pb-16 md:py-[10vh]"
      isOpen={open}
      onDismiss={close}
    >
      <DialogContent
        className="mx-auto max-h-full w-4/5 overflow-y-auto overscroll-y-none p-0 md:w-[430px]"
        style={{
          marginTop: 0,
          marginBottom: 0,
        }}
      >
        <div className="relative col-span-2 gap-4 text-p">
          <button
            className="close-button absolute top-3 right-3 z-50 grid h-10 w-10 place-items-center"
            onClick={close}
            aria-label="Close Newsletter Popup"
          >
            <Icon
              name="cross"
              className="h-[33px] w-[33px]"
              style={{ color: banderolePopupCloseIconColor as string }}
            />
          </button>
          <div className="relative h-[185px] md:h-[235px]">
            <Image
              src={banderoleImage}
              placeholder="empty"
              fill
              alt=""
              className="absolute"
              style={{ objectFit: 'cover' }}
            />
          </div>
          <div className="px-2 py-4 md:px-4 md:pb-6 md:pt-4">
            <Headline className="px-4 text-center font-thin" type="h3">
              {banderoleItems}
            </Headline>
            <div className="pb-0">
              <NewsletterForm hasDesktopPadding={false} isBandPopup={true} btnType="Main" />
              <div className="-mt-2 px-4">
                {typeof disclaimerOne === 'string' ? (
                  <p className="mt-2 text-[8px] leading-[10px] text-[#000000] lgx:mt-6 [&>p]:text-[8px]">
                    {disclaimerOne}
                  </p>
                ) : (
                  <RTEParser
                    className={clsx(
                      'mt-2 text-[#000000] lgx:mt-6 lgx:text-[8px] [&>p]:text-[8px] [&>p]:leading-[10px] lgx:[&>p]:text-[8px] lgx:[&>p]:leading-[10px]',
                    )}
                    rteJSONContent={disclaimerOne?.json}
                    links={disclaimerOne?.links as RteRteLinks}
                  />
                )}
                {disclaimerTwo && (
                  <RTEParser
                    className={clsx(
                      '-mt-2 -mb-2 lgx:mb-0 lgx:text-[8px] [&>p]:text-[8px] [&>p]:leading-[10px] lgx:[&>p]:text-[8px] lgx:[&>p]:leading-[10px]',
                    )}
                    rteJSONContent={disclaimerTwo?.json}
                    links={disclaimerTwo?.links as RteRteLinks}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </DialogOverlay>
  )
}
