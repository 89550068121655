import * as React from 'react'
import clsx from 'clsx'
import { Link } from '../Link/Link'
import { Maybe } from '@contentfulTypes'
import { isExternal } from '~/lib/externalLink'

export type ButtonStyle =
  | 'Main'
  | 'Secondary'
  | 'Tertiary'
  | 'AlternateColor' //Background Color and Text Color should be passed to Button in className prop
  | 'BigText'
  | 'MediumText'
  | 'MediumLinkText'
  | 'SmallText'
  | 'TextlinkPlainSmall'

export type Props = {
  children?: React.ReactNode | JSX.Element
  href?: string | Maybe<string>
  // sets a real anchor link if needed
  realLink?: string
  className?: string
  disabled?: boolean
  fullWidth?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type']
  ctaButtonStyle: ButtonStyle
  isTextMediumBold?: boolean
  isStyledLikeLink?: boolean
  title?: string
  form?: string
}

export const Button = ({
  children,
  type,
  href = '',
  realLink,
  className,
  disabled,
  fullWidth,
  ctaButtonStyle,
  isTextMediumBold,
  isStyledLikeLink,
  title,
  form,
  ...rest
}: Props) => {
  const getStyleClassesButton = (ctaButtonStyle: ButtonStyle) => {
    const baseStylesTextButton = clsx(
      className && className,
      isTextMediumBold && 'font-medium',
      'underline underline-offset-8',
      disabled ? 'text-grey-medium cursor-not-allowed' : 'decoration-primary-black',
    )

    const MainBasic = clsx(
      fullWidth && 'w-full',
      'inline-block text-center text-[16px] leading-[22px] border border-[2px] border-solid py-[19px] font-medium lg:transition-color lg:duration-300 lg:ease-out',
    )

    const Tertiary = `${MainBasic} border-grey-medium-3 hover:border-secondary-soft-black hover:bg-secondary-soft-black hover:text-primary-white`

    switch (ctaButtonStyle) {
      case 'Main':
        return clsx(
          MainBasic,
          'text-primary-white',
          disabled
            ? 'bg-grey-medium-3 cursor-not-allowed border-grey-medium-3'
            : 'bg-secondary-soft-black hover:bg-grey-dark-2 border-grey-dark-2',
        )
      case 'Secondary':
        return clsx(
          MainBasic,
          'text-secondary-soft-black',
          disabled
            ? 'bg-grey-medium-3 cursor-not-allowed text-primary-white border-grey-medium-3'
            : 'border-secondary-soft-black hover:bg-secondary-soft-black hover:text-primary-white hover:border-secondary-soft-black',
        )
      case 'Tertiary':
        return clsx(Tertiary)
      case 'AlternateColor':
        return clsx(
          'button',
          disabled
            ? 'bg-transparent cursor-not-allowed'
            : 'hover:border-secondary-soft-black hover:bg-secondary-soft-black hover:text-primary-white hover:decoration-primary-white',
        )
      case 'BigText':
        return clsx(baseStylesTextButton, 'text-link-txt-big')
      case 'MediumLinkText':
        return clsx(baseStylesTextButton, 'text-[16px] md:text-[22px]')
      case 'MediumText':
        return clsx(baseStylesTextButton, 'text-p')
      case 'SmallText':
        return clsx(baseStylesTextButton, 'text-p-small')
      case 'TextlinkPlainSmall':
        return clsx(
          'text-[12px] leading-[22px] text-grey-dark-2 underline underline-offset-8 decoration-1 decoration-grey-dark-2 font-normal',
        )
      default:
        return ''
    }
  }

  if (href) {
    return (
      <Link
        href={href}
        className={clsx(className && className, getStyleClassesButton(ctaButtonStyle))}
        title={title}
      >
        {children}
      </Link>
    )
  }

  if (realLink) {
    return (
      <a
        href={realLink}
        className={clsx(className && className, getStyleClassesButton(ctaButtonStyle))}
        title={title}
      >
        {children}
      </a>
    )
  }
  return (
    <button
      form={form}
      type={type}
      title={title}
      name={title ?? 'Custom Button'}
      disabled={disabled}
      className={clsx(
        getStyleClassesButton(ctaButtonStyle),
        'inline-block',
        className && className,
      )}
      {...rest}
    >
      {children}
    </button>
  )
}
